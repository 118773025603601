export default {
    label: 'Insert password here to see the PDF',
    submit: 'Enter',
    download: 'Download PDF',
    quick_rules: 'Il regolamento veloce',
    go_to_full_rules: 'Vai al Regolamento completo',
    goal: 'Obiettivo del gioco',
    goal_description: 'L\'obiettivo del gioco è formare la band al completo con 4 elementi',
    players_number: 'Numero di giocatori',
    players_number_description: 'Da 2 a 5 giocatori',
    preparation: 'Preparazione della partita',
    card_to_remove: 'Carte da rimuovere',
    card_to_remove_description: 'In base al numero di giocatori, rimuovete le seguenti carte:',
    card_distribution: 'Distribuzione delle carte',
    card_distribution_description: 'Per iniziare distribuite le carte seguendo queste istruzioni:',
    table_organization:'Organizzazione del tavolo da gioco:',
    turn_organization: 'Svolgimento del turno del giocatore:',
    download_full_rules: 'Scarica il regolamento completo:'
};