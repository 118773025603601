export default {
    title: 'Contacts',
    subtitle: 'Any questions? Suggestions? Keep in touch with us!',
    instagram: 'Or write us directly on ',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send',
    error: 'There are some errors. Please, check the fields above',
    message_sent: 'Message sent!',
    thanks: 'Thank you for contacting us, we will reply asap',
    privacy: 'I declare that I have read and accept the {privacy_policy}',
    privacy_policy: 'Privacy Policy',
    errors: {
        mandatory: 'The field \'{field}\' is required',
        email_wrong: 'Check your email',
    }
};