import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

import Home from "./components/pages/Home";
import Rules from "./components/pages/Rules";
import Band from "./components/pages/Band";
import Contacts from "./components/pages/Contacts";
import Page404 from "./components/pages/Page404";
import Cookie from "./components/pages/Cookie";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: { 
            title: 'The Band - il Gioco di Carte - Card Game',
            description: 'The Band - Sito ufficiale del gioco di carte adatto a tutti basato sul mondo della musica'
        }
    },
    { 
        path: "/rules", 
        name: "rules", 
        component: Rules,
        meta: { 
            title: 'Regolamento - The Band card game',
            description: 'Il regolamento di The Band, il gioco di carte basato sulla musica (attualmente passowrd protected)'
        }
    },
    { 
        path: "/the-band", 
        name: "band", 
        component: Band,
        meta: { 
            title: 'La nostra band - The Band card game',
            description: 'I membri della nostra band: ossia gli ideatori e creatori di Tha Band, il gioco di carte basato sul mondo musicale'
        }
    },
    { 
        path: "/contacts", 
        name: "contacts", 
        component: Contacts,
        meta: { 
            title: 'Contatti - The Band card game',
            description: 'Se vuoi contattare gli ideatori del gioco The Band scrivi nel form di questa pagina o direttamente sul nostro profilo Instagram'
        }
    },
    { 
        path: "/cookie-policy", 
        name: "cookie", 
        component: Cookie,
        meta: { 
            title: 'Cookie Policy - The Band card game',
            description: 'The Band Card Game - Our website\'s Cookie policy'
        }
    },
    { 
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: Page404,
        meta: { 
            title: '404 - The Band card game',
            description: 'Are you lost?'
        }
    }
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes, // short for `routes: routes`
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.name !== "notFound") {   
        // mostro un loader di pagina
        store.commit("changeState", {
            index: "loading",
            value: true
        });
        
        // chiudo sempre il menu
        store.commit("hideMenu");
    }

    document.title = to.meta.title;
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
    document.querySelector('meta[name="og:title"]').setAttribute('content', to.meta.title);
    document.querySelector('meta[name="og:description"]').setAttribute('content', to.meta.description);
    document.querySelector('meta[name="og:image"]').setAttribute('content', window.location.href + 'assets/images/carte-band.png');

    return next();
});

router.afterEach((/* to, from, failure */) => {
    // mostro un loader di pagina
    store.commit("changeState", {
        index: "loading",
        value: false
    });
});


export default router;
