<template>
    <section class="bg-primary flex justify-center md:p-24 p-12 text-white">
        <div class="text-center">
            <p class="text-2xl">
                <slot></slot>
            </p>
            <button class="bg-primary-dark font-semibold hover:-translate-y-1 hover:bg-primary-light mt-8 px-5 py-4 rounded-full text-white transform transition" @click="$emit('clickCta')">
                {{ buttonText }}
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CtaSection',
    props: {
        buttonText: String
    }
}
</script>