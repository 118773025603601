export default {
    first_section: {
        first_paragraph: {
            text: 'The Band es un {card_game} fácil de aprender y rápido de jugar.',
            card_game: 'juego de cartas'
        },
        second_paragraph: {
            text: 'El objetivo del juego es formar su propia banda (cantante, guitarrista, bajista, baterista), pero cuidado con los otros jugadores: ellos también tendrán tu mismo propósito.',
        },
        third_paragraph: {
            text: 'Los oponentes pueden interponerse en su camino con {groupies} encarnizadas, {producer} listos para todo y {head_hunter} sin escrúpulos. No se preocupen, sin embargo, sus {bodyguard} los protegerán de cualquier jugador malintencionado.',
            groupies: 'Groupies',
            producer: 'Producer',
            head_hunter: 'Head Hunter',
            bodyguard: 'Guardaespaldas',
        },
        cta: 'Descubre cómo Jugar'
    },
    second_section: {
        title: 'Easy to learn, {master}!',
        master: 'easy to master',
        cta: 'Aprende a jugar'
    },
    third_section: {
        title: 'Let\'s Rock!',
        first_paragraph: {
            text: 'Prepara tu banda y organiza tu concierto.'
        },
        second_paragraph: {
            text: 'Los instrumentos en la mano del {roadie} se inflaman y cuando estés listo para asistir al concierto de Woodstock, busca en la habitación del hotel a tu {guitar}. Conociéndolo, podría haber firmado un contrato con otra banda.',
            roadie: 'Roadie',
            guitar: 'Guitarrista'
        },
        third_paragraph: {
            text: `Vuelve a las Audiciones para reclutar un nuevo músico, con la esperanza de que tu {drummer} no se deprima  y se apunte una pistola a la cabeza.{br}
            O que el {singer} no caiga en el viejo hábito de las Rockstar y se vea obligado, por un período de tiempo, a retirarse a Rehab.`,
            drummer: 'Baterista',
            singer: 'Cantante'
        },
        cta: 'Descubre nuestra “Band”'
    },
    fourth_section: {
        title: '¿Quiere saber más?',
        cta: 'Contacto'
    }
};