<template>
    <div id="main" class="h-full min-h-screen">
        <loader v-if="$store.state.loading"></loader>
        <div class="bg-gray-100 min-h-screen">
            <navbar></navbar>
            <router-view @click="$store.commit('closeLangMenu')"></router-view>
            <Footer @click="$store.commit('closeLangMenu')"></Footer>
            <cookie-bar v-if="$store.state.showCookieBar"></cookie-bar>
        </div>
    </div>
</template>

<script>
import Navbar from "./components/parts/Navbar";
import Footer from "./components/parts/Footer";
import Loader from "./components/parts/Loader";
import CookieBar from "./components/parts/CookieBar";

export default {
    name: "App",
    components: {
        Navbar,
        Footer,
        Loader,
        CookieBar
    },
    mounted() {        
        setTimeout(() => {
            document.getElementById('loader').remove();
        }, 500)
    }
};
</script>
