<template>
    <section :id="id" class="flex md:px-36 px-5 py-12">
        <div class="bg-white flex flex-col md:flex-row md:p-10 p-5 py-10 rounded-3xl shadow-xl w-full">
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Card',
    props: {
        id: String
    }
}
</script>