export default {
    first_section: {
        first_paragraph: {
            text: 'The Band è un {card_game} facile da imparare e veloce da giocare.',
            card_game: 'gioco di carte'
        },
        second_paragraph: {
            text: 'Lo scopo del gioco è quello di formare la propria band (cantante, chitarrista, bassista, batterista), ma attenzione agli altri giocatori: anche loro avranno il vostro stesso scopo!',
        },
        third_paragraph: {
            text: 'Gli avversari potranno mettervi i bastoni fra le ruote con {groupies} accanite, {producer} pronti a tutto e {head_hunter} senza scrupoli. Non preoccupatevi, però: i vostri {bodyguard} vi proteggeranno da ogni giocatore malintenzionato.',
            groupies: 'Groupies',
            producer: 'Producer',
            head_hunter: 'Head Hunter',
            bodyguard: 'Bodyguard',
        },
        cta: 'Scopri come giocare'
    },
    second_section: {
        title: 'Easy to learn, {master}!',
        master: 'easy to master',
        cta: 'Impara a giocare'
    },
    third_section: {
        title: 'Let\'s Rock!',
        first_paragraph: {
            text: 'Prepara la tua Band e organizza il tuo concerto.'
        },
        second_paragraph: {
            text: 'Gli strumenti in mano al {roadie} si infiammano e quando sei pronto per partecipare al concerto di Woodstock, cerca nella camera dell\'hotel il tuo {guitar}. Conoscendolo, potrebbe aver firmato un contratto con un\'altra Band.',
            roadie: 'roadie',
            guitar: 'chitarrista'
        },
        third_paragraph: {
            text: `Riparti dalle Audizioni per reclutare un nuovo musicista, nella speranza che il tuo {drummer} non cada in depressione e si punti una pistola alla tempia.{br}
            O che il {singer} non cada nel vecchio vizio delle Rockstar e sia obbligato, per un periodo di tempo, a ritirarsi in Rehab.`,
            drummer: 'batterista',
            singer: 'cantante'
        },
        cta: 'Scopri la nostra "Band"'
    },
    fourth_section: {
        title: 'Vuoi saperne di più?',
        cta: 'Contattaci'
    }
};