export default {
    first_section: {
        first_paragraph: {
            text: 'The Band is a {card_game} that is really easy to learn.',
            card_game: 'flash card game'
        },
        second_paragraph: {
            text: 'The goal of the game is to form your own band (singer, guitarist, bassist, drummer), but watch out for other players: they will also have the same goal as you!',
        },
        third_paragraph: {
            text: 'Opponents could get in your way by using fierce {groupies}, greedy {producer} and ruthless {head_hunter}. But don’t worry: your {bodyguard} will protect you from any attackers.',
            groupies: 'Groupies',
            producer: 'Producers',
            head_hunter: 'Headhunters',
            bodyguard: 'Bodyguard',
        },
        cta: 'How to play'
    },
    second_section: {
        title: 'Easy to learn, {master}!',
        master: 'easy to master',
        cta: 'Learn how to play'
    },
    third_section: {
        title: 'Let\'s Rock!',
        first_paragraph: {
            text: 'Form your band and organize your concert.'
        },
        second_paragraph: {
            text: 'The instruments in the {roadie}\'s hands ignite, and when you\'re ready to attend the Woodstock concert, search the hotel room for your {guitar}. If I know him well, he may have signed a contract with another band.',
            roadie: 'Roadie',
            guitar: 'Guitarist'
        },
        third_paragraph: {
            text: `Recruit a new musician through Auditions, in hopes that your {drummer} won't fall into depression and put a gun to his head.{br}
            And pray that the {singer} doesn't fall into the old Rockstar vice and is forced, for a period of time, to retire to Rehab.
            `,
            drummer: 'Drummer',
            singer: 'Singer'
        },
        cta: 'Our "Band"'
    },
    fourth_section: {
        title: 'Want to know more?',
        cta: 'Contact us'
    }
};