export default {
    title: 'Contacts',
    subtitle: 'Avez-vous des questions? Suggestions? Restez en contact avec nous!',
    instagram: 'Ou écrivez nous sur ',
    name: 'Nom',
    email: 'Email',
    message: 'Message',
    send: 'Send',
    error: 'There are some errors. Please, check the fields above',
    message_sent: 'Message envoyé !',
    thanks: 'Merci de nous contacter, nous vous répondrons dès que possible',
    privacy: 'J\'ai lu et j\'accepte la {privacy_policy}',
    privacy_policy: 'politique de confidentialité',
    errors: {
        mandatory: 'The field \'{field}\' is required',
        email_wrong: 'Check your email',
    }
};