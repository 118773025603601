export default {
    rules: 'Rules',
    prev: 'Prev',
    next: 'Next',
    steps: {
        first: {
            title: 'Goal of the game',
            text: 'The goal of the game is to <strong>form your band</strong> with 4 classical musicians: Singer, Guitarist, Bassist, Drummer.'
        },
        second: {
            title: 'Draw and play',
            text: 'Draw a card from center deck and play as many cards as you want. The cards you play go into your personal discard pile, called the <strong>Backstage</strong>.'
        },
        third: {
            title: 'Events',
            text: '<strong>Warning!</strong> If you draw an <strong>event</strong> card you must skip the turn and suffer (or all players suffer) the effects of the event.  Events are discarded into a common pile and cannot be owned by players.'
        },
        fourth: {
            title: 'End your turn',
            text: 'To end your turn you can have a maximum of 4 cards in your hand. If you have more, you can discard the excess cards in your Backstage (without using them) and pass the turn.'
        },
        fifth: {
            title: 'Easy, right?',
            text: 'See? As easy as playing a triangle!<br>Now you just have to try it for real 😉'
        },
    }
};