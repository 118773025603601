<template>
    <div class="w-full">
        <card class="w-full">
            <div class="flex flex-col w-full">

                <!-- Avviso per utenti non italiani -->
                <div v-if="$store.state.currentLanguage !== 'it'" class="bg-primary-light font-bold mb-5 px-5 py-8 rounded-xl text-center text-white text-lg">
                    {{ $t('common.page_not_available_language') }}
                </div>

                <h2 class="font-bold leading-loose text-3xl text-primary text-center">
                    {{ $t('rules.quick_rules') }}
                </h2>

                <div class="text-center">
                    <a class="font-bold hover:text-primary text-primary-dark" href="#download-rules" v-smooth-scroll>{{ $t('rules.go_to_full_rules') }}</a>
                </div>

                <!-- Obiettivo del gioco -->
                <h3 class="text-2xl mt-10 text-center">
                   {{ $t('rules.goal') }}
                </h3>
                <p class="text-center">
                    {{ $t('rules.goal_description') }}
                </p>

                <!-- Numero giocatori -->
                <h3 class="text-2xl mt-10 text-center">
                    {{ $t('rules.players_number') }}
                </h3>
                <p class="text-center">
                    {{ $t('rules.players_number_description') }}
                </p>

                <!-- Preparazione partita -->
                <h3 class="text-2xl mt-10 text-center">
                    {{ $t('rules.preparation') }}
                </h3>
                <p class="font-bold mt-10 text-center">
                    {{ $t('rules.card_to_remove') }}
                </p>
                <p class="text-center">
                    {{ $t('rules.card_to_remove_description') }}
                </p>
                <div class="md:p-5 flex flex-col md:flex-row">
                    <div class="w-full md:w-1/3 mb-3 md:mb-0 flex justify-center items-start"><img src="../../assets/images/quick-rules/remove-cards-1.png" alt="" style="max-width: 250px;"></div>
                    <hr class="block md:hidden my-5">
                    <div class="w-full md:w-1/3 mb-3 md:mb-0 flex justify-center items-start"><img src="../../assets/images/quick-rules/remove-cards-2.png" alt="" style="max-width: 250px;"></div>
                    <hr class="block md:hidden my-5">
                    <div class="w-full md:w-1/3 mb-3 md:mb-0 flex justify-center items-start"><img src="../../assets/images/quick-rules/remove-cards-3.png" alt="" style="max-width: 200px;"></div>
                </div>
                <p class="font-bold mt-10 text-center">
                    {{ $t('rules.card_distribution') }}
                </p>
                <p class="text-center">
                    {{ $t('rules.card_distribution_description') }}
                </p>
                <div class="md:p-5 flex flex-col md:flex-row">
                    <div class="flex items-start justify-center md:w-1/3 w-full"><img src="../../assets/images/quick-rules/prepare-deck-1.png" alt="" style="max-width: 200px;"></div>
                    <div class="flex items-start justify-center md:w-1/3 w-full"><img src="../../assets/images/quick-rules/prepare-deck-2.png" alt="" style="max-width: 200px;"></div>
                    <div class="flex items-start justify-center md:w-1/3 w-full"><img src="../../assets/images/quick-rules/prepare-deck-3.png" alt="" style="max-width: 200px;"></div>
                </div>
                
                <!-- Tavolo -->
                <h3 class="text-2xl mt-10 text-center">
                    {{ $t('rules.table_organization') }}
                </h3>
                <div class="md:p-5 flex flex-col md:flex-row">
                    <div class="flex items-start justify-center w-full"><img src="../../assets/images/quick-rules/table.png" alt="" class="w-full" style="max-width: 1000px;"></div>
                </div>
                
                <!-- Turno -->
                <h3 class="text-2xl mt-10 mb-10 text-center">
                    {{ $t('rules.turn_organization') }}
                </h3>
                <div class="md:p-5 flex flex-col md:flex-row">
                    <div class="flex items-start justify-center w-full"><img src="../../assets/images/quick-rules/turno-flow-chart.png" alt="" class="w-full" style="max-width: 800px;"></div>
                </div>
            </div>
        </card>
        <card id="download-rules" class="w-full">
            <div class="flex flex-col w-full justify-center items-center">
                <h3 class="text-2xl mb-10 text-center">
                    {{ $t('rules.download_full_rules') }}
                </h3>
                <button class="bg-primary text-white font-semibold hover:-translate-y-1 hover:bg-primary-dark px-12 py-4 rounded-full transform transition disabled:opacity-50 disabled:cursor-default disabled:translate-y-0 disabled:bg-primary" type="button" @click="downloadPdf">{{ $t('rules.download') }}</button>
            </div>
        </card>
    </div>
</template>

<script>
import Card from '../parts/Card.vue'
import sha256 from 'js-sha256'
import isMobile from 'ismobilejs';

export default {
    name: 'Rules',
    components: {
        Card
    },
    data() {
        return {
            hashed: "91f4796311ee58ab67425e1e7222d39d9ec6b7a44bdbd47e600b3a11c29b5905",
            pdf: "/pdf/Regolamento_completo_The_Band-v1-2021-12.pdf",
            pwd: null,
            // isMobile: null
        }
    },
    computed: {
        isMobile() {
            return isMobile().any
        }
    },
    mounted() {
        // this.isMobile = this.$isMobile()
    },
    methods: {
        validatePassword() {
            if (this.checkPassword(this.pwd)) {
                this.locked = false;
            }
        },
        hashedPassword(password) {
            return sha256(password)
        },
        checkPassword(password) {
            return this.hashedPassword(password) === this.hashed;
        },
        downloadPdf() {
            window.open(this.pdf, '_blank')
        }
    }
}
</script>