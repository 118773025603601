export default {
    rules: 'Regras',
    prev: 'Prev',
    next: 'Next',
    steps: {
        first: {
            title: 'Objetivo do jogo',
            text: 'O objetivo do jogo é formar sua banda com 4 músicos clássicos: Cantor, Guitarrista, Baixista, Baterista.'
        },
        second: {
            title: 'Pega e jogue',
            text: 'Pega uma carta do baralho central e jogue quantas cartas quiser. As cartas que você jogar vão para sua pilha de descarte pessoal, chamada de <strong>Backstage</strong>.'
        },
        third: {
            title: 'Eventos',
            text: '<strong>Aviso!</strong> Se você tirar um cartão de evento você deve pular o turno e sofrer (ou todos os jogadores sofrem) os efeitos do evento. Os eventos são descartados em uma pilha comum e não podem ser de propriedade dos jogadores.'
        },
        fourth: {
            title: 'Terminar a sua vez',
            text: 'Para terminar a sua vez, você pode ter um máximo de 4 cartas na sua mão. Se você tiver mais, você pode descartar os cartões em excesso em seu Backstage (sem usá-los) e passar o turno.'
        },
        fifth: {
            title: 'É fácil, não é?',
            text: 'Vês? Tão fácil como jogar um triângulo!<br>Agora você só tem que tentar de verdade 😉'
        },
    }
};