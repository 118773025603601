export default {
    title: 'A Banda',
    subtitle: 'As mentes por trás do jogo',
    flavio: {
        first_paragraph: 'Ele é, de fato, um cantor em sua vida musical e talvez alguns de vocês o conheçam pelo nome artístico de Falloppio.',
        second_paragraph: 'Apaixonado pela música por toda sua vida, como o melhor Makelele dá o máximo para os projetos em que participa. Ele é a alma do nosso grupo e ele é o nosso homem-chave.'
    },
    francesco: {
        first_paragraph: 'Ele é na verdade um tecladista na vida musical e é por isso que o teclado será o próximo instrumento introduzido em futuras expansões de The Band',
        second_paragraph: 'Graças a ele, podemos pagar alguns conjuntos de teste maravilhosos e ele é o único que coloca ordem nas ideias que vem do grupo.'
    },
    alessandro: {
        first_paragraph: 'Ele não é um baixista, ele nem sabe tocar nada além de campainhas de casas, mas ele é um entusiasta da música e isso é o suficiente para nós.',
        second_paragraph: 'Nerd e desenvolvedor, passa a maior parte do seu tempo na frente de um PC (mas ele também tem falhas). Tenta trazer a lógica que ele adora tanto quanto a banda.'
    },
    luca: {
        first_paragraph: 'Como qualquer baterista, ele trabalha tranquilamente mas é um verdadeiro artista: sem suas ilustrações, nossos trabalhos seriam apenas cartas com textos.',
        second_paragraph: 'Ilustrador Alan Ford, Diabolik, Disney... Você imediatamente entende que ele não é uma piada quando começa a desenhar.'
    },
};