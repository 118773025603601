import { createApp } from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { createI18n } from 'vue-i18n'
import VueGtag from "vue-gtag";
import './index.css'   
import Cookies from 'js-cookie'

import store from "./store" 

import router from "./routes"

import axios from "axios"

const getLanguageFromCode = (code) => {
    let arr = code.split("-");
    return arr[0];
}

window.axios = axios;
window.mailgun = {};
window.mailgun.baseUrl = "https://api.mailgun.net/v3/sandbox93e0d61dccd5429697035d844abac991.mailgun.org"

window.languages = [
    'it', 'en', 'es', 'pt', 'fr'
];

// Prendo il linguaggio del browser e lo setto come predefinito
window.browser_language = navigator.language ? getLanguageFromCode(navigator.language): 'it';
let cookie_language = Cookies.get('the-band_sl');
store.commit('changeState', {
    index: 'defaultLanguage',
    value: 'en'
});
store.commit('changeState', {
    index: 'currentLanguage',
    value: cookie_language ? cookie_language : window.browser_language
});

// creo i18n
import messages from './translations'

const i18n = createI18n({
    locale: store.state.currentLanguage,
    fallbackLocale: 'en',
    messages
})

createApp(App).use(router)
    .use(store)
    .use(VueSmoothScroll, {
        duration: 500,
        updateHistory: false,
    })
    .use(i18n)
    .use(VueGtag, {
        config: { id: "G-TNNFY358EG" },
        enabled: false
    }, router)
    .mount('#app')


