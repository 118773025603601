export default {
    rules: 'Regulación',
    prev: 'Prev',
    next: 'Next',
    steps: {
        first: {
            title: 'Objetivo del juego',
            text: 'El objetivo del juego es <strong>formar la banda</strong> con 4 elementos diferentes: Cantante, Guitarrista, Bajista, Baterista.'
        },
        second: {
            title: 'Coge y juega',
            text: 'Coge desde la baraja central y juega todas las cartas que quieras. Las cartas que juegas terminan en tu pila de descartes personal, llamada <strong>Backstage</strong>.'
        },
        third: {
            title: 'Eventos',
            text: '<strong>¡Atención!</strong> Si coges un <strong>evento</strong>, saltas el turno y sufres (o todos los jugadores sufren) los efectos del evento. Los eventos se descartan en una pila común y no pueden ser poseídos por los jugadores.'
        },
        fourth: {
            title: 'Termina el turno',
            text: 'Para terminar el turno hay que tener un máximo de 4 cartas en la mano. Si tienes más, puedes descartar los excesos en tu Backstage (sin utilizarlos) y pasar el turno.'
        },
        fifth: {
            title: 'Easy, right?',
            text: '¿Ves? ¡Tan fácil como tocar un triángulo!<br>Ahora sólo tienes que probarlo de verdad 😉'
        }
    }
};