<template>
    <div class="fixed flex h-full items-center justify-center left-0 md:py-0 py-5 top-0 w-full z-50">
        <div class="bg-white h-5/6 md:h-tutorial md:w-1/2 relative rounded-xl w-11/12 z-10">
            <div class="absolute flex h-auto justify-center left-0 py-5 top-0 w-full z-10">
                <div class="flex justify-around md:w-1/3 w-1/2">
                    <div v-for="step, index in steps" :key="'stepper-'+index" class="bg-gray-200 p-1 rounded-full">
                        <span :class="['block rounded-full', currentStep == index ? 'bg-primary' : 'bg-gray-200']" style="height: 18px; width: 18px;"></span>
                    </div>
                </div>
            </div>

            <!-- Close -->
            <div class="absolute flex h-auto justify-end py-5 right-5 top-0 z-20">
                <div class="cursor-pointer" @click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>
                </div>
            </div>

            <!-- Steps -->
            <tutorial-step v-for="step, index in steps" 
                :key="'step-'+index" 
                :index="index" 
                :step="step"
                :currentStep="currentStep"></tutorial-step>

            <!-- Comandi -->
            <div class="absolute md:left-16 left-1 h-full flex items-center z-10">
                <button v-show="currentStep > 0" class="bg-primary font-semibold md:hover:-translate-y-1 md:hover:bg-primary-dark md:px-5 md:py-4 px-3 py-2 rounded-full text-white transform transition" @click="prev">{{$t('tutorial.prev')}}</button>
            </div>
            <div class="absolute flex h-full items-center md:right-16 right-1 z-10">
                <button v-show="currentStep < (steps.length - 1)" class="bg-primary font-semibold md:hover:-translate-y-1 md:hover:bg-primary-dark md:px-5 md:py-4 px-3 py-2 rounded-full text-white transform transition" @click="next">{{$t('tutorial.next')}}</button>
                <!-- Regolamento, tasto finale -->
                <router-link :to="{name: 'rules'}" v-show="currentStep === (steps.length - 1)" class="border-2 border-primary font-semibold hover:-translate-y-1 hover:bg-primary hover:text-white md:px-5 md:py-4 px-3 py-2 rounded-full text-primary transform transition">{{$t('tutorial.rules')}}</router-link>
            </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-40" @click="close"></div>
    </div>
</template>

<script>
import TutorialStep from "./TutorialStep";

export default {
    name: 'Tutorial',
    props: {
        opened: Boolean
    },
    components: {
        TutorialStep
    },
    data() {
        return {
            steps: [
                {
                    title: this.$t('tutorial.steps.first.title'),
                    image: require("../../assets/images/carte-band.png"),
                    description: this.$t('tutorial.steps.first.text')
                },
                {
                    title: this.$t('tutorial.steps.second.title'),
                    image: require("../../assets/images/mazzo.png"),
                    description: this.$t('tutorial.steps.second.text')
                },
                {
                    title: this.$t('tutorial.steps.third.title'),
                    image: require("../../assets/images/eventi.png"),
                    description: this.$t('tutorial.steps.third.text')
                },
                {
                    title: this.$t('tutorial.steps.fourth.title'),
                    image: require("../../assets/images/roadie.png"),
                    description: this.$t('tutorial.steps.fourth.text')
                },
                {
                    title: this.$t('tutorial.steps.fifth.title'),
                    image: require("../../assets/images/introducer.png"),
                    description: this.$t('tutorial.steps.fifth.text')
                },
            ],
            currentStep: 0
        }
    },
    methods: {
        prev() {
            this.currentStep = this.currentStep - 1
        },
        next() {
            this.currentStep = this.currentStep + 1
        },
        close() {
            this.$emit('closeModal')
        }
    }
}
</script>