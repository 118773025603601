export default {
    title: 'Contatti',
    subtitle: 'Hai domande? Suggerimenti? Rimani in contatto con noi!',
    instagram: 'Oppure scrivici su ',
    name: 'Nome',
    email: 'Email',
    message: 'Messaggio',
    send: 'Invia',
    error: 'Ci sono degli errori, controlla i campi',
    message_sent: 'Messaggio inviato!',
    thanks: 'Grazie per averci contattato, ti risponderemo al più presto',
    privacy: 'Dichiaro di aver letto e di accettare la {privacy_policy}',
    privacy_policy: 'Privacy Policy',
    errors: {
        mandatory: 'Il campo \'{field}\' è obbligatorio',
        email_wrong: 'Inserisci un\'email corretta',
    }
};