export default {
    title: 'Contactos',
    subtitle: '¿Tienes alguna pregunta? ¿Sugerencias? ¡Mantente en contacto con nosotros!',
    instagram: 'O escríbanos en ',
    name: 'Nombre',
    email: 'Correo',
    message: 'Mensaje',
    send: 'Entrar',
    error: 'Hay errores, por favor, compruebe los campos',
    message_sent: '¡Mensaje enviado!',
    thanks: 'Gracias por ponerse en contacto con nosotros, nos pondremos en contacto con usted lo antes posible.',
    privacy: 'Declaro que he leído y acepto la {privacy_policy}',
    privacy_policy: 'Privacy Policy',
    errors: {
        mandatory: 'El campo \'{field}\' es obligatorio',
        email_wrong: 'Por favor, introduzca un correo electrónico correcto',
    }
};