// IT
import itCommon from "./lang/it/common";
import itMenu from "./lang/it/menu";
import itHome from "./lang/it/home";
import itTutorial from "./lang/it/tutorial";
import itContacts from "./lang/it/contacts";
import itBand from "./lang/it/band";
import itRules from "./lang/it/rules";
import itCookie from "./lang/it/cookie";
// EN
import enCommon from "./lang/en/common";
import enMenu from "./lang/en/menu";
import enHome from "./lang/en/home";
import enTutorial from "./lang/en/tutorial";
import enContacts from "./lang/en/contacts";
import enBand from "./lang/en/band";
import enRules from "./lang/en/rules";
import enCookie from "./lang/en/cookie";
// ES
import esCommon from "./lang/es/common";
import esMenu from "./lang/es/menu";
import esHome from "./lang/es/home";
import esTutorial from "./lang/es/tutorial";
import esContacts from "./lang/es/contacts";
import esBand from "./lang/es/band";
import esRules from "./lang/es/rules";
// PT
import ptCommon from "./lang/pt/common";
import ptMenu from "./lang/pt/menu";
import ptHome from "./lang/pt/home";
import ptTutorial from "./lang/pt/tutorial";
import ptContacts from "./lang/pt/contacts";
import ptBand from "./lang/pt/band";
import ptRules from "./lang/pt/rules";
// FR
import frCommon from "./lang/fr/common";
import frMenu from "./lang/fr/menu";
import frHome from "./lang/fr/home";
import frTutorial from "./lang/fr/tutorial";
import frContacts from "./lang/fr/contacts";
import frBand from "./lang/fr/band";
import frRules from "./lang/fr/rules";

export default {
    it: {
        home: itHome,
        common: itCommon,
        tutorial: itTutorial,
        menu: itMenu,
        contacts: itContacts,
        band: itBand,
        rules: itRules,
        cookie: itCookie
    },
    en: {
        home: enHome,
        common: enCommon,
        tutorial: enTutorial,
        menu: enMenu,
        contacts: enContacts,
        band: enBand,
        rules: enRules,
        cookie: enCookie
    },
    es: {
        home: esHome,
        common: esCommon,
        tutorial: esTutorial,
        menu: esMenu,
        contacts: esContacts,
        band: esBand,
        rules: esRules
    },
    pt: {
        home: ptHome,
        common: ptCommon,
        tutorial: ptTutorial,
        menu: ptMenu,
        contacts: ptContacts,
        band: ptBand,
        rules: ptRules
    },
    fr: {
        home: frHome,
        common: frCommon,
        tutorial: frTutorial,
        menu: frMenu,
        contacts: frContacts,
        band: frBand,
        rules: frRules
    }
}