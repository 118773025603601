export default {
    rules: 'Regole',
    prev: 'Prev',
    next: 'Next',
    steps: {
        first: {
            title: 'Obiettivo del gioco',
            text: 'L\'obiettivo del gioco è <strong>formare la band</strong> con 4 diversi elementi: Cantante, Chitarrista, Bassista, Batterista.'
        },
        second: {
            title: 'Pesca e gioca',
            text: 'Pesca dal mazzo centrale e gioca tutte le carte che vuoi. Le carte che giochi finiscono nella tua pila degli scarti personale, detta <strong>Backstage</strong>.'
        },
        third: {
            title: 'Gli Eventi',
            text: '<strong>Attenzione!</strong> Se peschi un <strong>evento</strong> salti il turno e subisci (o tutti i giocatori subiscono) gli effetti dell\'evento. Gli eventi si scartano in una pila comune e non possono essere posseduti dai giocatori.'
        },
        fourth: {
            title: 'Termina il turno',
            text: 'Per terminare il turno devi avere massimo 4 carte in mano. Se ne hai di più puoi scartare quelle in eccesso nel tuo Backstage (senza usarle) e passare il turno.'
        },
        fifth: {
            title: 'Easy, right?',
            text: 'Visto? Facile come suonare un triangolo!<br>Ora non ti resta che provarlo davvero 😉'
        },
    }
};