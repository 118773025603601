import { createStore } from "vuex";
import Cookies from 'js-cookie'

export default createStore({
    state: {
        modalOpen: false,
        mobileMenuVisible: false,
        defaultLanguage: '',
        currentLanguage: '',
        showLangMenu: false,
        loading: true,
        showCookieBar: !Cookies.get('_c_required') && !Cookies.get('_c_all')
    },
    mutations: {
        toggleModal(state, modal) {
            state.modalOpen = modal;
        },
        showMenu(state) {
            state.mobileMenuVisible = true;
        },
        hideMenu(state) {
            state.mobileMenuVisible = false;
        },
        toggleLangMenu(state) {
            state.showLangMenu = !state.showLangMenu;
        },
        closeLangMenu(state) {
            state.showLangMenu = false;
        },
        changeState(state, payload) {
            state[payload.index] = payload.value;
        },
    },
});
