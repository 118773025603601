export default {
    title: 'Contatos',
    subtitle: 'Você tem alguma dúvida? Sugestões? Entre em contato conosco!',
    instagram: 'Ou nos escreva no ',
    name: 'Nome',
    email: 'E-mail',
    message: 'Mensagem',
    send: 'Mandar',
    error: 'There are some errors. Please, check the fields above',
    message_sent: 'Mensagem enviada!',
    thanks: 'Obrigado por nos contatar, responderemos assim que possível',
    privacy: 'Li e aceito a {privacy_policy}',
    privacy_policy: 'Política de Privacidade',
    errors: {
        mandatory: 'The field \'{field}\' is required',
        email_wrong: 'Check your email',
    }
};