<template>
    <div class="w-full flex justify-center">
        <card class="md:w-2/3">
            <div class="flex flex-col items-center justify-around md:flex-row p-10 w-full">
                <div>
                    <img :src="imageUrl" alt="" class="md:w-48 w-32">
                </div>
                <div class="text-center mt-5 md:mt-0">
                    <h1 class="text-8xl">
                        404
                    </h1>
                    <p class="my-5 text-3xl">
                        {{ $t('common.lost') }}
                    </p>
                    <router-link :to="{name: 'home'}" class="bg-primary font-semibold hover:-translate-y-1 hover:bg-primary-dark mt-5 px-5 py-4 rounded-full text-white transform transition inline-block">
                        {{ $t('common.back_home') }}
                    </router-link>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
import Card from '../parts/Card.vue'
import isMobile from 'ismobilejs';

export default {
    name: 'Page404',
    components: {
        Card
    },
    data() {
        return {
            imageUrl: null
        }
    },
    computed: {
        isMobile() {
            return isMobile().any
        }
    },
    mounted() {
        let img = new Image();

        img.onload = () => {
            this.imageUrl = img.src;
        }

        img.src = require("../../assets/images/producer.png");
    },
    methods: {
    }
}
</script>