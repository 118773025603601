<template>
    <div class="fixed flex h-full items-center justify-center left-0 top-0 w-full z-50">
        <div class="bg-white md:p-20 md:text-base md:w-1/2 p-8 relative rounded-xl text-sm w-11/12 z-10">
            <p class="font-bold">{{$t('cookie.title')}}</p>
            <p>{{ $t('cookie.subtitle') }}<br><a class="font-bold hover:text-primary-dark text-primary" target="_blank" href="/pdf/cookie-policy.pdf">{{ $t('cookie.policy') }}</a>.</p>
            <div class="flex flex-col items-center justify-between md:flex-row mt-5">
                <button @click="acceptCookies('required')" class="bg-white border-2 border-primary hover:bg-primary-dark hover:border-primary-dark hover:text-white md:w-auto p-4 rounded-full text-primary w-full">
                    {{ $t('cookie.accept_required') }}
                </button>
                <button @click="acceptCookies('all')" class="bg-primary border-2 border-primary hover:bg-primary-dark hover:border-primary-dark md:mt-0 md:w-auto mt-5 p-4 rounded-full text-white w-full">
                    {{ $t('cookie.accept_all') }}
                </button>
            </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-40"></div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: 'CookieBar',
    props: {
        opened: Boolean
    },
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.$emit('closeCookieBar')
        },
        acceptCookies(type) {
            Cookies.set('_c_'+type, true, {expires: 365});
            this.$store.commit('changeState', {
                index: 'showCookieBar',
                value: false
            });
            if (type === 'all') {   
                this.$gtag.optIn();
            } else {
                this.$gtag.optOut();
            }
        }
    }
}
</script>