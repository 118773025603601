<template>
    <div class="bg-primary-lighter bottom-0 fixed flex flex-col h-screen items-center justify-center left-0 right-0 top-0 w-full z-50">
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>

        <!-- <logo styleClass="fill-current mt-5 w-20"></logo> -->
    </div>
</template>

<script>
// import Logo from '../svgs/Logo'  

export default {
    name: "Loader",
    components: {
        // Logo
    }
}
</script>

<style>

#loader ul {
  margin: 0;
  list-style: none;
  width: 90px;
  position: relative;
  padding: 0;
  height: 10px;
}
#loader ul li {
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #000;
  bottom: 0;
}
@keyframes sequence1 {
  0% {
    height: 10px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 10px;
  }
}
@keyframes sequence2 {
  0% {
    height: 20px;
  }
  50% {
    height: 65px;
  }
  100% {
    height: 20px;
  }
}
#loader li:nth-child(1) {
  left: 0;
  animation: sequence1 1s ease infinite 0;
}
#loader li:nth-child(2) {
  left: 15px;
  animation: sequence2 1s ease infinite 0.1s;
}
#loader li:nth-child(3) {
  left: 30px;
  animation: sequence1 1s ease-in-out infinite 0.2s;
}
#loader li:nth-child(4) {
  left: 45px;
  animation: sequence2 1s ease-in infinite 0.3s;
}
#loader li:nth-child(5) {
  left: 60px;
  animation: sequence1 1s ease-in-out infinite 0.4s;
}
#loader li:nth-child(6) {
  left: 75px;
  animation: sequence2 1s ease infinite 0.5s;
}

    </style>