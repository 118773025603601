export default {
    first_section: {
        first_paragraph: {
            text: 'The Band est un {card_game} facile à apprendre et rapide à jouer.',
            card_game: 'jeu de cartes'
        },
        second_paragraph: {
            text: 'Le but de ce jeu est de former votre propre groupe (chanteur, guitariste, bassiste, batteur) mais attention aux autres joueurs : ils auront le même but.',
        },
        third_paragraph: {
            text: 'L\'adversaire pourra se mettre en travers de votre chemin avec des {groupies} passionnées, les {producer} et {head_hunter} sans scrupules seront prêt à tout. N\'ayez pas peur, pensez : votre {bodyguard} vous protégera de tous les attaquants.',
            groupies: 'groupies',
            producer: 'producteurs',
            head_hunter: 'chasseurs de tête',
            bodyguard: 'garde du corps',
        },
        cta: 'Découvrez comment jouer'
    },
    second_section: {
        title: 'Easy to learn, {master}!',
        master: 'easy to master',
        cta: 'Apprenez à jouer'
    },
    third_section: {
        title: 'Let\'s Rock!',
        first_paragraph: {
            text: 'Créer votre groupe et organiser votre concert.'
        },
        second_paragraph: {
            text: 'Les instruments du groupe dans les mains des "{roadie}" sont chauds et quand vous serez prêt pour le concert de Woodstock, chercher une chambre d\'hôtel pour votre {guitar}. Le connaissant, il pourrait bien avoir signé un contrat avec un autre groupe.',
            roadie: 'Roadies',
            guitar: 'guitarist'
        },
        third_paragraph: {
            text: `Faites des auditions pour recruter un nouveau musicien dans l'espoir que votre {drummer} ne tombe pas en dépression et pointe un pistolet sur sa tempe.{br}
            Ou que le {singer} ne retombe pas dans ses vieux vices de Rockstar ; pendant une période ; pour aller en cure de désintoxication.
            `,
            drummer: 'batteur',
            singer: 'chanteur'
        },
        cta: 'Découvrez notre groupe'
    },
    fourth_section: {
        title: 'Want to know more?',
        cta: 'Contact us'
    }
};