export default {
    title: 'Le groupe',
    subtitle: 'L’esprit derrière le jeu',
    flavio: {
        first_paragraph: 'C\'est un vrai chanteur dans sa vie musicale. Certains d\'entre vous le connaissent par le nom de scène de Falloppio',
        second_paragraph: 'Toute une vie de passion pour la musique. Comme le meilleur Makelele il donne tout pour les projets auquel il prend part. Il est l’âme du groupe, il notre homme clé.'
    },
    francesco: {
        first_paragraph: 'C\'est un joueur de Clavier (Synthé) dans sa vie musicale. C\'est pourquoi le clavier sera le prochain instrument introduit dans l extension du groupe.',
        second_paragraph: 'Merci à lui, grâce a lui nous pourrons nous permettre quelques merveilleux ensembles de tests,  il est aussi le seul qui met de l\'ordre dans les idées qui viennent du groupe.'
    },
    alessandro: {
        first_paragraph: 'Il n\'est pas bassiste; il ne sait même pas sonner quoi que ce soit en dehors de la cloche. Mais il est un expert en musique et cela nous suffit.',
        second_paragraph: 'Geek et développeur, il passe la plupart du temps devant son PC (mais il a aussi des défauts). Il essaye d\'apporter une logique qu il aime comme le groupe.'
    },
    luca: {
        first_paragraph: 'Comme tous les batteurs,  il travaille doucement, mais il est un vrai artiste ;sans ses illustrations notre papier serait juste des cartes avec des écritures.',
        second_paragraph: 'Illustrateur Alan Ford, Diabolik, Disney...vous comprenez tout de suite que ce n\'est pas une blague quand il commence à dessiner.'
    },
};