export default {
    rules: 'Règles',
    prev: 'Prev',
    next: 'Next',
    steps: {
        first: {
            title: 'But du jeu',
            text: 'Le but du jeu est de former votre groupe avec 4 musiciens: Chanteur, Guitariste, Bassiste, Batteur.'
        },
        second: {
            title: 'Dessiner et jouer',
            text: 'Dessinez une carte à partir du jeu central et jouez autant de cartes que vous voulez. Les cartes que vous jouez entrent dans votre pile de défausse personnelle, appelée <strong>Backstage</strong>.'
        },
        third: {
            title: 'Événements',
            text: '<strong>Attention!</strong> Si vous dessinez une carte d’événement, vous devez sauter le tour et souffrir (ou tous les joueurs souffrent) les effets de l’événement. Les événements sont jetés dans une pile commune et ne peuvent pas être possédés par les joueurs.'
        },
        fourth: {
            title: 'Terminez votre tour',
            text: 'Pour terminer votre tour, vous pouvez avoir un maximum de 4 cartes dans votre main. Si vous en avez plus, vous pouvez jeter les cartes excédentaires dans vos Backstage (sans les utiliser) et passer le tour.'
        },
        fifth: {
            title: 'Facile, n’est-ce pas ?',
            text: 'Vois-tu ? Aussi facile que de jouer un triangle !<br>Maintenant tu dois juste essayer pour de vrai 😉'
        },
    }
};