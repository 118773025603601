<template>
    <div>
        <section class="bg-primary bg-image-hero flex flex-col h-hero items-center justify-center text-white">
            <logo styleClass="h-64 fill-current"></logo>
            <a href="#card-game" class="bg-white font-semibold hover:-translate-y-1 hover:bg-gray-200 mt-8 px-5 py-4 rounded-full text-primary-dark text-white transform transition" v-smooth-scroll>
                {{ $t('common.card_game') }}
            </a>
        </section>
        <card id="card-game" class="md:py-32">
            <div class="flex justify-center items-center md:w-3/12">
                <div class="p-8 rounded-2xl">
                    <img src="../../assets/images/bass-guitar.png" class="max-h-96" alt="">
                </div>
            </div>
            <div class="flex flex-col justify-center md:px-10 md:w-9/12 px-5">
                <h2 class="font-bold leading-loose text-3xl text-primary">
                    {{ $t('common.card_game') }}
                </h2>
                <div>
                    <i18n-t keypath="home.first_section.first_paragraph.text" tag="p" class="my-5">
                        <template #card_game>
                            <strong>{{ $t('home.first_section.first_paragraph.card_game') }}</strong>
                        </template>
                    </i18n-t>
                    <i18n-t keypath="home.first_section.second_paragraph.text" tag="p" class="my-5">
                    </i18n-t>
                    <i18n-t keypath="home.first_section.third_paragraph.text" tag="p" class="my-5">
                        <template #groupies>
                            <strong>{{ $t('home.first_section.third_paragraph.groupies') }}</strong>
                        </template>
                        <template #producer>
                            <strong>{{ $t('home.first_section.third_paragraph.producer') }}</strong>
                        </template>
                        <template #head_hunter>
                            <strong>{{ $t('home.first_section.third_paragraph.head_hunter') }}</strong>
                        </template>
                        <template #bodyguard>
                            <strong>{{ $t('home.first_section.third_paragraph.bodyguard') }}</strong>
                        </template>
                    </i18n-t>
                </div>
                <div class="flex justify-center md:justify-start">
                    <button @click="tutorial(true)" class="bg-primary font-semibold hover:-translate-y-1 hover:bg-primary-dark mt-5 px-5 py-4 rounded-full text-white transform transition inline-block">
                        {{ $t('home.first_section.cta') }}
                    </button>
                </div>
            </div>
        </card>
        <cta-section :buttonText="$t('home.second_section.cta')" @clickCta="tutorial(true)">
            <i18n-t keypath="home.second_section.title" tag="span">
                <template #master>
                    <strong>{{ $t('home.second_section.master') }}</strong>
                </template>
            </i18n-t>
        </cta-section>
        <card class="md:py-32">
            <div class="flex flex-col justify-center md:px-10 md:w-9/12 px-5">
                <h2 class="font-bold leading-loose text-3xl text-primary">{{ $t('home.third_section.title') }}</h2>
                <div>
                    <i18n-t keypath="home.third_section.first_paragraph.text" tag="p" class="my-5">
                    </i18n-t>

                    <i18n-t keypath="home.third_section.second_paragraph.text" tag="p" class="my-5">
                        <template #roadie>
                            <strong>{{ $t('home.third_section.second_paragraph.roadie') }}</strong>
                        </template>
                        <template #guitar>
                            <strong>{{ $t('home.third_section.second_paragraph.guitar') }}</strong>
                        </template>
                    </i18n-t>

                    <i18n-t keypath="home.third_section.third_paragraph.text" tag="p" class="my-5">
                        <template #drummer>
                            <strong>{{ $t('home.third_section.third_paragraph.drummer') }}</strong>
                        </template>
                        <template #br>
                            <br>
                        </template>
                        <template #singer>
                            <strong>{{ $t('home.third_section.third_paragraph.singer') }}</strong>
                        </template>
                    </i18n-t>
                </div>
                <div class="flex justify-center md:justify-start">
                    <router-link :to="{name: 'band'}" class="bg-primary font-semibold hover:-translate-y-1 hover:bg-primary-dark mt-5 px-5 py-4 rounded-full text-white transform transition inline-block">
                        {{ $t('home.third_section.cta') }}
                    </router-link>
                </div>
            </div>
            <div class="flex justify-center items-center md:w-3/12">
                <div class="p-8 rounded-2xl">
                    <img src="../../assets/images/singer.png" class="max-h-96" alt="">
                </div>
            </div>
        </card>
        <cta-section :buttonText="$t('home.fourth_section.cta')" @clickCta="$router.push({name: 'contacts'})">
            {{ $t('home.fourth_section.title') }}
        </cta-section>

        <tutorial v-show="showTutorial" @closeModal="showTutorial = false"></tutorial>
    </div>
</template>

<script>
import Card from '../parts/Card.vue'
import CtaSection from '../parts/CtaSection.vue'
import Tutorial from '../parts/Tutorial.vue'
import Logo from '../svgs/Logo.vue'

export default {
    components: { Logo, CtaSection, Card, Tutorial },
    name: 'Home',
    data() {
        return {
            showTutorial: false
        }
    },
    methods: {
        tutorial(show) {
            this.showTutorial = show;
        }
    }
}
</script>