<template>
    <object class="w-full h-hero" data="/pdf/cookie-policy.pdf" type="application/pdf">
        <div>No PDF viewer available</div>
    </object>
</template>

<script>
export default {
    name: 'CookiePolicy'
}
</script>