<template>
    <div  :class="['absolute flex flex-col h-full items-center justify-center left-0 px-5 md:px-20 pt-20 pb-10 top-0 w-full transform transition-all', classesByStep(index)]" style="transition: transform 100ms ease, opacity 400ms ease;">
        <div class="h-1/6">
            <h4 class="font-bold text-3xl text-primary">
                {{ step.title }}
            </h4>
        </div>
        <div class="flex h-3/6 items-center justify-center md:py-0 py-5 w-full">
            <img v-if="imageUrl" :src="imageUrl" alt="" class="h-full">
        </div>
        <div class="flex h-2/6 items-center text-center">
            <p v-html="step.description"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TutorialStep',
    props: {
        index: null,
        step: {},
        currentStep: Number,
    },
    data() {
        return {
            imageUrl: null
        }
    },
    methods: {
        classesByStep(step) {
            if (step === this.currentStep) {
                return 'opacity-100';
            }
            if (step < this.currentStep) {
                return 'opacity-0 -translate-x-screen-x';
            }
            if (step > this.currentStep) {
                return 'opacity-0 translate-x-screen-x';
            }
        },
    },
    mounted() {
        let img = new Image();

        img.onload = () => {
            console.log(this.index+' - img loaded');
            this.imageUrl = img.src;
        }

        img.src = this.step.image;
    }
}
</script>