<template>
    <div class="fixed flex h-full items-center justify-center left-0 top-0 w-full z-50">
        <div class="bg-white h-screen md:h-tutorial md:w-1/2 relative rounded-xl w-11/12 z-10">

            <!-- Steps -->
            <div :key="'step-'+index" class="flex flex-col h-full items-center justify-center pb-10 pt-10 px-20 w-full" style="transition-duration: 100ms;">
                <iframe class="w-full h-full" src="https://www.iubenda.com/privacy-policy/91930057" frameborder="0"></iframe>
            </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-40" @click="close"></div>
    </div>
</template>

<script>
export default {
    name: 'Tutorial',
    props: {
        opened: Boolean
    },
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.$emit('closeModal')
        }
    }
}
</script>