export default {
    first_section: {
        first_paragraph: {
            text: 'A banda (ou The Band) é um {card_game} fácil de aprender e rápido de jogar.',
            card_game: 'jogo de cartas'
        },
        second_paragraph: {
            text: 'O objetivo do jogo é formar sua própria banda (cantor, guitarrista, baixista, baterista), mas cuidado com os outros jogadores: eles também terão o mesmo objetivo!',
        },
        third_paragraph: {
            text: 'Os adversários serão capazes de ficar no caminho com {groupies} implacáveis, {producer} prontos para qualquer coisa e {head_hunter} sem escrúpulos. Não se preocupe, porém: seus {bodyguard} irão protegê-lo de qualquer oponente.',
            groupies: 'Groupies',
            producer: 'Produtores',
            head_hunter: 'Caça-Talentos',
            bodyguard: 'Guarda-Costas',
        },
        cta: 'Aprenda a jogar'
    },
    second_section: {
        title: 'Easy to learn, {master}!',
        master: 'easy to master',
        cta: 'Aprenda a jogar'
    },
    third_section: {
        title: 'Let\'s Rock!',
        first_paragraph: {
            text: 'Prepare sua banda e organize seu concerto.'
        },
        second_paragraph: {
            text: 'Os instrumentos nas mãos do {roadie} ainda estão quentes e quando você estiver pronto para o show de Woodstock, procure no quarto do hotel pelo {guitar}. Conhecendo-o,  ele pode ter assinado um contrato com outra banda.',
            roadie: 'Roadie',
            guitar: 'Guitarrista'
        },
        third_paragraph: {
            text: `Saia em audições para recrutar um novo músico na esperança de que o baterista não caia em depressão e aponte uma arma para sua própria cabeça.{br}
            Ou que a {singer} não caia no velho vício dos Rockstars e seja obrigado, por um tempo, a ir para a reabilitação.
            `,
            drummer: 'baterista',
            singer: 'cantor'
        },
        cta: 'Descubra a nossa banda'
    },
    fourth_section: {
        title: 'Want to know more?',
        cta: 'Contact us'
    }
};