export default {
    title: 'La Banda',
    subtitle: 'Las mentes detrás del juego',
    flavio: {
        first_paragraph: 'De hecho, es un cantante en su vida musical, y quizás alguno de ustedes lo conozca con el nombre de Falloppio.',
        second_paragraph: 'Apasionado por la música de toda la vida, como el mejor Makelele da todo por los proyectos en los que participa. Es el alma de nuestro grupo y nuestro verdadero líder.'
    },
    francesco: {
        first_paragraph: 'En realidad es un teclista en la vida musical, y es por eso que el teclado será el próximo instrumento introducido en las futuras expansiones de The Band.',
        second_paragraph: 'Gracias a él, podemos permitirnos unos magníficos juegos de prueba, y también es quien pone orden en las ideas que salen del grupo.'
    },
    alessandro: {
        first_paragraph: 'No es un bajista, no sabe tocar nada fuera de los timbres, pero es un aficionado a la música y eso es suficiente.',
        second_paragraph: 'Juguetón y desarrollador, pasa la mayor parte de su tiempo delante de un PC (pero también tiene defectos). Intenta llevar la lógica que tanto le importa a la Banda.'
    },
    luca: {
        first_paragraph: 'Como todo baterista trabaja detrás de la escena, pero es un verdadero artista: sin sus ilustraciones nuestros papeles serían sólo cartulinas con inscripciones.',
        second_paragraph: 'Dibujante de Alan Ford, Diabolik, Disney... Se ve que es un hombre que no bromea cuando se trata de ilustrar.'
    },
};