export default {
    title: 'La Band',
    subtitle: 'Le menti dietro il gioco',
    flavio: {
        first_paragraph: 'È a tutti gli effetti un cantante nella sua vita musicale e forse qualcuno di voi lo conosce con il nome d\'arte di Falloppio.',
        second_paragraph: 'Appassionato di musica da una vita, come il miglior Makelele dà tutto per i progetti a cui prende parte. È l\'anima del nostro gruppo, nonché il nostro vero e proprio uomo di punta.'
    },
    francesco: {
        first_paragraph: 'In realtà è un tastierista nella vita musicale, ed è per questo motivo che la tastiera sarà il prossimo strumento introdotto nelle future espansioni di The Band.',
        second_paragraph: 'Grazie a lui possiamo permetterci degli stupendi mazzi di test ed è anche colui che mette ordine tra le idee che escono dal gruppo.'
    },
    alessandro: {
        first_paragraph: 'Non è un bassista, non sa nemmeno suonare altro al di fuori dei campanelli, ma è un appassionato di musica e questo ci basta.',
        second_paragraph: 'Smanettone e sviluppatore, passa la maggior parte del suo tempo davanti a un PC (però ha anche dei difetti). Cerca di portare la logica che gli sta tanto a cuore anche nella Band.'
    },
    luca: {
        first_paragraph: 'Come ogni vero batterista lavora in sordina, ma è un vero e proprio artista: senza le sue illustrazioni le nostre carte sarebbero solo dei cartoncini con delle scritte sopra.',
        second_paragraph: 'Disegnatore di Alan Ford, Diabolik, Disney... Si capisce subito che è uno che non scherza quando si tratta di illustrare.'
    },
};