<template>
    <div>
        <section class="bg-primary-light relative flex bg-top justify-center md:p-24 p-12 text-white" style="background-image: url('/assets/images/band-bg-wide.jpg')">
            <div class="absolute bg-black h-full left-0 opacity-50 top-0 w-full"></div>
            <div class="text-center z-10">
                <h1 class="font-bold mb-4 mt-10 text-5xl">
                    {{ $t('band.title') }}
                </h1>
                <h2 class="mb-10 text-2xl">
                    {{ $t('band.subtitle') }}
                </h2>
            </div>
        </section>
        <div class="md:py-12">
            <card v-for="member, index in members" :key="'member-'+index" class="md:py-12">
                <div class="flex justify-center items-center md:w-3/12">
                    <div class="p-8">
                        <div class="bg-center bg-cover bg-no-repeat border-2 border-gray-700 h-44 rounded-full w-44" :style="['background-image: url(' + member.image + ')']">
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center md:w-9/12 px-10">
                    <h2 class="font-bold leading-loose text-3xl text-primary">
                        {{ member.name }}
                    </h2>
                    <div v-html="member.description"></div>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import Card from '../parts/Card.vue'
export default {
    name: 'Band',
    components: { 
        Card
    },
    beforeMount() {
        // preload immagine sfondo header
        let headerBg = new Image();
        headerBg.src = '/assets/images/band-bg-wide.jpg';
        // preload immagini dei membri della band
        this.members.forEach((member) => {
            let img = new Image();
            img.src = member.image;
        });
    },
    data() {
        return {
            members: [
                {
                    name: "Flavio Grosso",
                    image: "/assets/images/singer-avatar.png",
                    description: `<p class="my-5">
                            `+ this.$t('band.flavio.first_paragraph') +`
                        </p>
                        <p class="my-5">
                            `+ this.$t('band.flavio.second_paragraph') +`
                        </p>
                        <p class="font-mono font-semibold mt-8 text-xl">
                            Frontman 🎤
                        </p>`
                },
                {
                    name: "Francesco Bosco",
                    image: "/assets/images/guitar-avatar.png",
                    description: `<p class="my-5">
                            `+ this.$t('band.francesco.first_paragraph') +`
                        </p>
                        <p class="my-5">
                            `+ this.$t('band.francesco.second_paragraph') +`
                        </p>
                        <p class="font-mono font-semibold mt-8 text-xl">
                            Conductor 🎼
                        </p>`
                },
                {
                    name: "Alessandro Pietrantonio",
                    image: "/assets/images/bass-guitar-avatar.png",
                    description: `<p class="my-5">
                            `+ this.$t('band.alessandro.first_paragraph') +`
                        </p>
                        <p class="my-5">
                            `+ this.$t('band.alessandro.second_paragraph') +`
                        </p>
                        <p class="font-mono font-semibold mt-8 text-xl">
                            Coder 💻
                        </p>`
                },
                {
                    name: "Luca Bonardi",
                    image: "/assets/images/drummer-avatar.png",
                    description: `<p class="my-5">
                            `+ this.$t('band.luca.first_paragraph') +`
                        </p>
                        <p class="my-5">
                            `+ this.$t('band.luca.second_paragraph') +`
                        </p>
                        <p class="font-mono font-semibold mt-8 text-xl">
                            Artist 🎨
                        </p>`
                },
            ]
        }
    }
}
</script>