<template>
    <div>
        <section
            class="bg-primary bg-image-hero flex flex-col items-center justify-center text-white md:p-24 p-12"
        >
            <div class="text-center z-10">
                <h1 class="font-bold mb-4 mt-10 text-5xl">
                    {{ $t('contacts.title') }}
                </h1>
                <h2 class="mb-5 text-2xl">
                    {{ $t('contacts.subtitle') }}
                </h2>
                <h4 class="mb-10 text-lg">
                    {{ $t('contacts.instagram') }} <a href="https://www.instagram.com/the_band_game/" target="_blank" class="font-bold hover:text-primary-lighter">Instagram <svg class="inline fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"/></svg></a>
                </h4>
            </div>
        </section>
        <section class="flex justify-center w-full">
            <section class="flex md:w-1/2 p-0 py-24">
                <div
                    v-if="!messageSent"
                    class="-mt-40 bg-white flex p-10 rounded-3xl shadow-xl w-full"
                >
                    <form action="#" class="w-full flex flex-col">
                        <div class="w-full">
                            <div class="flex flex-col">
                                <label for="">
                                    {{$t('contacts.name')}}*
                                </label>
                                <input type="text" class="border-2 mb-4 mt-3 p-2 rounded-xl" v-model="form.name">
                                <p v-if="errors.name" class="font-bold mb-4 text-red text-red-600 text-sm">{{ errors.name }}</p>
                            </div>
                            <div class="flex flex-col">
                                <label for="">
                                    {{$t('contacts.email')}}*
                                </label>
                                <input type="email" class="border-2 mb-4 mt-3 p-2 rounded-xl" v-model="form.email">
                                <p v-if="errors.email" class="font-bold mb-4 text-red text-red-600 text-sm">{{ errors.email }}</p>
                            </div>
                        </div>
                        <div class="w-full flex flex-col">
                            <label for="">
                                {{$t('contacts.message')}}*
                            </label>
                            <textarea v-model="form.message" class="border-2 mb-4 mt-3 p-2 rounded-xl" id="" cols="30" rows="5"></textarea>
                            <p v-if="errors.message" class="font-bold mb-4 text-red text-red-600 text-sm">{{ errors.message }}</p>
                        </div>
                        <div class="w-full py-5 text-center">
                            <label for="privacy" class="">
                                <input type="checkbox" id="privacy" v-model="form.privacy"> <i18n-t keypath="contacts.privacy">
                                    <template #privacy_policy>
                                        <span class="iubenda-white iubenda-embed text-primary hover:underline cursor-pointer" :title="$t('contacts.privacy_policy')" target="_blank" @click="showPrivacy = true">{{ $t('contacts.privacy_policy') }}</span>
                                    </template>
                                </i18n-t>
                            </label>
                        </div>
                        <div class="w-full py-5 text-center flex justify-center">
                            <vue-recaptcha siteKey="6LefosscAAAAALARQTAK-5yqfOCZHAlS3e19oaXi"
                                size="normal" 
                                theme="light"
                                :tabindex="0"
                                @verify="recaptchaVerified"
                                @expire="recaptchaExpired"
                                @fail="recaptchaFailed"
                                ref="vueRecaptcha"></vue-recaptcha>
                        </div>
                        <div class="w-full flex justify-center">
                            <button class="bg-primary text-white font-semibold hover:-translate-y-1 hover:bg-primary-dark px-12 py-4 rounded-full transform transition disabled:opacity-50 disabled:cursor-default disabled:translate-y-0 disabled:bg-primary" type="button" @click="validate" :disabled="buttonDisabled">{{$t('contacts.send')}}</button>
                        </div>
                        <p v-if="Object.keys(errors).length > 0" class="text-center font-bold mt-4 text-red text-red-600 text-sm">{{$t('contacts.error')}}</p>
                    </form>
                </div>
                <div v-if="messageSent" class="bg-green-200 p-20 rounded-3xl text-green-700 w-full text-center">
                    <p class="mb-4 text-2xl">
                        {{$t('contacts.message_sent')}}
                    </p>
                    <p>
                        {{$t('contacts.thanks')}}
                    </p>
                </div>
            </section>
        </section>

        <privacy v-show="showPrivacy" @closeModal="showPrivacy = false"></privacy>
    </div>
</template>

<script>

import vueRecaptcha from 'vue3-recaptcha2'
import Privacy from '../parts/Privacy.vue'

export default {
    name: "Contacts",
    components: {
        vueRecaptcha,
        Privacy
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                message: null,
                privacy: null
            },
            errors: {},
            recatpcha: false,
            recatpchaToken: null,
            messageSent: false,
            showPrivacy: false
        }
    },
    computed: {
        buttonDisabled() {
            return this.form.privacy === null || this.recatpcha === false;
        }
    },
    methods: {
        validate() {
            let validationErrors = {};

            if (this.form.name === null || this.form.name === "") {
                validationErrors.name = this.$t('contacts.errors.mandatory', {field: this.$t('contacts.name')});
            }

            if (this.form.email === null || this.form.email === "") {
                validationErrors.email = this.$t('contacts.errors.mandatory', {field: this.$t('contacts.email')});
            }

            if (!this.validateEmail(this.form.email)) {
                validationErrors.email = this.$t('contacts.errors.email_wrong');
            }

            if (this.form.message === null || this.form.message === "") {
                validationErrors.message = this.$t('contacts.errors.mandatory', {field: this.$t('contacts.message')});
            }

            if (Object.keys(validationErrors).length > 0) {
                this.errors = validationErrors;
            } else {
                this.send();
            }
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        recaptchaVerified(response) {
            this.recatpchaToken = response;
            this.recatpcha = true;
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
            // Non fare nulla
        },
        send() {
            let _this = this;

            const data = {
                from: _this.form.name + " <" + _this.form.email + ">",
                to: "thebandcardgame@gmail.com",
                subject: "Nuovo messaggio dal sito da " + _this.form.name,
                text: _this.form.message
            };

            window.axios.post(window.mailgun.baseUrl + "/messages", null, 
                {
                    params: data,
                    auth: {
                        username: 'api',
                        password: '83070e5e4649b4c7f8d78fb0f3b982e3-2ac825a1-c67fc412'
                    },  
                })
                .then(() => {
                    _this.messageSent = true;
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    }
};
</script>
