<template>
    <header class="bg-white flex font-semibold h-header items-center justify-between left-0 md:px-20 px-5 shadow sticky text-primary-dark top-0 w-full z-20">
        <div class="flex h-full md:w-6/12">
            <router-link :to="{name: 'home'}">
				<logo styleClass="h-full fill-current"></logo>
			</router-link>
        </div>
        <div class="bg-gray-100 flex md:hidden md:w-6/12 p-2 rounded-full" @click="toggleMenu">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z"/><path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z"/></svg>
        </div>
        <div :class="['absolute bg-white flex-col items-end md:flex md:flex-row md:items-center md:justify-end md:mt-0 md:static md:w-auto md:h-full mt-2 px-8 py-4 right-2 rounded-2xl top-header w-8/12 z-10', menuHidden ? 'hidden' : '']">
            <router-link :to="{name: 'home'}" :class="['content-center flex h-full hover:text-primary-light items-center md:ml-10 md:my-0 md:px-0 md:py-0 my-1 px-2 py-2 rounded-xl router-link-active router-link-exact-active']" active-class="text-primary-light">{{ $t('menu.home') }}</router-link>
            <router-link :to="{name: 'band'}" :class="['content-center flex h-full hover:text-primary-light items-center md:ml-10 md:my-0 md:px-0 md:py-0 my-1 px-2 py-2 rounded-xl router-link-active router-link-exact-active']" active-class="text-primary-light">{{ $t('menu.band') }}</router-link>
            <router-link :to="{name: 'rules'}" :class="['content-center flex h-full hover:text-primary-light items-center md:ml-10 md:my-0 md:px-0 md:py-0 my-1 px-2 py-2 rounded-xl router-link-active router-link-exact-active']" active-class="text-primary-light">{{ $t('menu.rules') }}</router-link>
            <router-link :to="{name: 'contacts'}" :class="['content-center flex h-full hover:text-primary-light items-center md:ml-10 md:my-0 md:px-0 md:py-0 my-1 px-2 py-2 rounded-xl router-link-active router-link-exact-active']" active-class="text-primary-light">{{ $t('menu.contacts') }}</router-link>
            <a href="https://www.instagram.com/the_band_game/" target="_blank" class="content-center flex h-full hover:text-primary-light items-center md:ml-10 md:my-0 md:px-0 md:py-0 my-1 px-2 py-2 rounded-xl router-link-active router-link-exact-active">
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"/></svg>
            </a>
            <!-- Switcher delle lingue desktop -->
            <div class="md:px-0 hidden md:flex">
                <button class="content-center flex h-7 h-full hover:bg-primary-light hover:text-primary-light items-center md:ml-10 md:my-0 my-1 p-1 rounded-full rounded-xl router-link-active router-link-exact-active w-7" @click="$store.commit('toggleLangMenu')">
                    <img :src="'/assets/images/flags/' + $store.state.currentLanguage + '.png'" alt="" class="w-full h-full">
                </button>
                <div class="absolute bg-white mr-28 mt-2 p-4 right-0 rounded-xl top-full flex" v-show="$store.state.showLangMenu">
                    <button v-for="(language, index) in availableLanguages" :key="'language-'+index" class="content-center flex h-7 h-full hover:bg-primary-light hover:text-primary-light items-center md:mx-2 md:my-0 my-1 p-1 rounded-full rounded-xl router-link-active router-link-exact-active w-7" @click="setCurrentLanguage(language)">
                        <img :src="'/assets/images/flags/' + language + '.png'" alt="" class="w-full h-full">
                    </button>
                </div>
            </div>
            <!-- Switcher delle lingue mobile -->
            <div class="px-2 flex justify-around md:hidden">
                <button v-for="(language, index) in availableLanguages" :key="'language-'+index" class="content-center flex h-7 h-full hover:bg-primary-light hover:text-primary-light items-center md:mx-2 md:my-0 my-1 p-1 rounded-full rounded-xl router-link-active router-link-exact-active w-7" @click="setCurrentLanguage(language)">
                    <img :src="'/assets/images/flags/' + language + '.png'" alt="" class="w-full h-full">
                </button>
            </div>
        </div>
        <div :class="['fixed w-full h-full top-0 left-0', menuHidden ? 'hidden' : '']" @click="$store.commit('hideMenu')"></div>
    </header>
</template>

<script>
import Logo from "../svgs/Logo"
import Cookies from 'js-cookie'

export default {
    name: "Navbar",
	components: {
		Logo
	},
    computed: {
        menuHidden() {
            return !this.$store.state.mobileMenuVisible;
        },
        availableLanguages() {
            let languages = [...window.languages];
            // rimuovo il current lang
            languages.splice(window.languages.indexOf(this.$store.state.currentLanguage), 1);
            return languages;
        }
    },
    data() {
        return {
        }
    },
    methods: {
        toggleMenu() {
            this.$store.commit(this.$store.state.mobileMenuVisible ? 'hideMenu' : 'showMenu');
        },
        setCurrentLanguage(lang) {
            this.$store.commit("changeState", {
                index: "loading",
                value: true
            });

            // setto il cookie in modo che il browser lo memorizzi
            Cookies.set('the-band_sl', lang, {expires: 365});
            // setto l'informazione nello storage
            this.$store.commit('changeState', {
                index: 'currentLanguage',
                value: lang
            })
            // configuro i18n per tradurre tutto
            this.$i18n.locale = lang;
            // chiudo il menu delle lingue
            this.$store.commit('changeState', {
                index: 'showLangMenu',
                value: false
            });

            // ricarico comunque la pagina per sicurezza (la pagina Band ne ha bisogno)
            location.reload();
        }
    }
};
</script>
