export default {
    title: 'Our Band',
    subtitle: 'The minds behind the game',
    flavio: {
        first_paragraph: 'He is a real singer in his musical life and maybe some of you know him by his stage name of Falloppio.',
        second_paragraph: 'A lifelong music enthusiast, like the best Makelele he gives himself entirely for the projects he takes part in. He is the soul of our group, as well as our real key man.'
    },
    francesco: {
        first_paragraph: 'Actually, he is a keyboard player in musical life, which is why the keyboard will be the next instrument introduced in future expansions of The Band.',
        second_paragraph: 'Thanks to him, we can afford wonderful test decks and he is also the one who brings order to the ideas coming out of the group.'
    },
    alessandro: {
        first_paragraph: 'He\'s not a bass player, he can\'t even play anything else outside of doorbells, but he\'s a music lover and that\'s enough for us.',
        second_paragraph: 'A geek and developer, he spends most of his time in front of a PC (but he has his flaws too). He tries to bring the logic he cares so much about to the Band as well.'
    },
    luca: {
        first_paragraph: 'Like any drummer, he works behind the scenes, but he is a true artist: without his illustrations, our cards would be just pieces of paper.',
        second_paragraph: 'Illustrator of Alan Ford, Diabolik, Disney... When it comes to drawing he absolutely knows his stuff: he\'s a pro.'
    },
};